import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../style/pages/404.scss"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <div className="page-container not-found">
      <h1>SORRY, PAGE NOT FOUND...</h1>
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </div>
  </Layout>
)

export default NotFoundPage
